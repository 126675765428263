
;(function() {
'use strict';

	function Toolkit() {
		//this.init();
	}

	Toolkit.prototype.addMessageBox = function(message, alerttype) {
		var messagebox = $('<div class="_'+ alerttype +'">'+ message +'</div>');
		messagebox.prependTo('.messages');
		this.alertTimeout(messagebox, 5000);
	};

	Toolkit.prototype.alertTimeout = function(messagebox, wait) {
		setTimeout(function() {
			messagebox.fadeOut('slow', function() {
				$(this).remove();
			});
		}, wait);
	};

	window.Toolkit = Toolkit;

})();

var toolkit = new Toolkit();

$(document).ready(function() {

	// Accordion
	$('.accordion__header').on('click', function() {
		var $this = $(this);
		$this.toggleClass('accordion__header--open')
			.siblings('.accordion__content')
			.slideToggle('fast');
	});

});